<div class="cardContainer card w-100 p-0 m-0 border-0 text-left {{type}}">
	<div class="cardThumbnailContainer item-card">
		<div *ngIf="lesson?.id > 0; else comingSoon">
			<a href="javascript://" class="link-top m-0 p-0" (click)="goToDetails($event)">
				<div class="card-title">
					<div class="triangle" *ngIf="lesson?.NgssFlag"><div class="ngss"><span class="sr-only">NGSS</span></div></div>
					<div class="text" [ngClass]="{short: lesson?.NgssFlag}" [innerHtml]="lesson?.title"></div>
				</div>
				<div class="image">
					<img [src]="lesson?.thumbnail?.url" alt="" />
					<div class="tag" *ngIf="lesson?.CardTopic?.title">{{ lesson?.CardTopic?.title }}</div>
				</div>
			</a>
			<div class="blurb">
				<div class="text" [innerHtml]="getBlurb()"></div>
				<div class="details">
					<button class="p-0" disabled>
						<span *ngIf="bannerType != 'lesson'; else btnContent">&nbsp;</span>
						<ng-template #btnContent>
							<i class="fas fa-clock" aria-label="Duration"></i>
							&nbsp; &nbsp;
							<span>{{ lesson?.duration }}</span>
						</ng-template>
					</button>
					<span class="right">
						<button aria-label="Likes" (click)="toggleLike()" [disabled]="!auth" [ngClass]="{clickable: auth}">
							<i class="far fa-thumbs-up" [ngClass]="{'fas': liked}"></i>
							&nbsp; &nbsp;
							<span>{{ likes }}</span>
						</button>
						<button aria-label="Share" (click)="shareLesson()" [disabled]="!auth" [ngClass]="{clickable: auth}" class="share">
							<i class="fa fa-share-alt"></i>
						</button>
						<button *ngIf="video" aria-label="Play Video" (click)="playVideo($event)" class="clickable">
							<i class="fas fa-play"></i>
						</button>
					</span>
				</div>
			</div>
			<div class="cta">
				<button class="btn btn-primary" (click)="goToDetails($event)"
				  *ngIf="bannerType != 'lesson' || (auth || type == 'lesson')"
				  [ngClass]="{'one': bannerType == 'lesson', 'full': type == 'collection'}">
					View Details
				</button>
				<div class="btn btn-primary one noAuth"
				  *ngIf="bannerType == 'lesson' && (!auth && type != 'lesson')">
					View Details
					<span class="tooltip">Sign in to launch content.</span>
				</div>
				<!-- <span *ngIf="bannerType == 'lesson'">&nbsp;</span> -->
				<button class="btn btn-primary two" (click)="launch($event)" 
				  *ngIf="bannerType == 'lesson' && (auth || type == 'lesson')"
				  [attr.aria-disabled]="type != 'lesson' && !lesson?.publicUrl">
					Launch <i class="fa fas fa-rocket"></i>
				</button>
				<div class="btn btn-primary two noAuth"
				  *ngIf="bannerType == 'lesson' && (!auth && type != 'lesson')">
					Launch <i class="fa fas fa-rocket"></i>
					<span class="tooltip">Sign in to launch content.</span>
				</div>
				<div class="clear"></div>
			</div>
		</div>
		<ng-template #comingSoon>
			<div class="coming-soon">
				<p class="line1">{{ lesson?.title }}</p>
				<p>{{ lesson?.blurb }}</p>
			</div>
		</ng-template>
	</div>
</div>