<div class="search-filter" [ngClass]="searchStatus ? 'search-filter-collaps' : ''">
	<div class="search-heading">
		<h2>Search Filters</h2>
		<button class="btn-collaps" (click)="searchToggle()" aria-label="sidebar navigation" aria-expanded="false">
			<i class="fas fa-sliders-h"></i>
			<i class="fas fa-chevron-left"></i>
		</button>
	</div>
	<ng-container *ngIf="loaded">
		<form [formGroup]="searchform">
			<div class="search-control">
				<div class="custom-fieldset-div exploring-div">
					<fieldset>
						<legend><i class="fas fa-toolbox" aria-hidden="true"></i> Exploring</legend>
						<app-checkbox-group
							[formGroup]="searchform"
							[data]="explorings"
							name="exploringArray"
							id="exploring"
							[noPadding]="true"
							[educatorMode]="educatorMode"
							[checkeds]="true"
						></app-checkbox-group>
						<div class="d-none" aria-live="polite">
							{{ exploringInfo }}
						</div>
					</fieldset>
				</div>

				<div class="custom-fieldset-div createdby-div">
					<fieldset>
						<legend><i class="fas fa-user"></i> Created By</legend>
						<app-checkbox-group
							[formGroup]="searchform"
							[data]="createdBys"
							name="checkArray"
							id="created-by"
							[noPadding]="true"
							[educatorMode]="educatorMode"
							[checkeds]="checkCreateby"
						></app-checkbox-group>
					</fieldset>
				</div>

				<div class="custom-fieldset-div audinece-div">
					<fieldset>
						<legend><i class="fas fa-book-open"></i> Content Area</legend>
						<app-checkbox-group
							[formGroup]="searchform"
							[data]="allAudienceTypes"
							name="audienceTypeArray"
							id="informal"
							[noPadding]="true"
							[educatorMode]="true"
							[checkeds]="checkAudience"
						></app-checkbox-group>
					</fieldset>
				</div>

				<div class="custom-fieldset-div grande-range-div">
					<app-grade-range [searchform]="searchform" [faIcon]="'school'"></app-grade-range>
				</div>

				<div class="custom-fieldset-div language-div">
					<fieldset>
						<legend><i class="fas fa-globe"></i> Languages</legend>
						<app-checkbox-group
							[formGroup]="searchform"
							[data]="languages"
							name="languageArray"
							id="language"
							[noPadding]="true"
							[educatorMode]="true"
							[checkeds]="checkLanguage"
						></app-checkbox-group>
					</fieldset>

				</div>

				<div class="custom-fieldset-div campatiblity-div">
					<fieldset>
						<legend><i class="fas fa-mobile-alt"></i> Compatibility</legend>
						<app-checkbox-group
							[formGroup]="searchform"
							[data]="browsers"
							name="hardwareArray"
							id="browser-hardware"
							[noPadding]="true"
							[educatorMode]="true"
							[checkeds]="checkHardware"
						></app-checkbox-group>
					</fieldset>
				</div>

				<div class="custom-fieldset-div sort-div">
					<fieldset>
						<legend><i class="fas fa-signal"></i> Sort By</legend>
						<ul	class="custom-grouping group-radios" [formGroup]="searchform">
							<li class="custom-control custom-radio">
								<input type="radio" class="custom-control-input" name="sortBy" id="soSortByDate"
									(change)="onSortChange($event)"
									value="date" [checked]="searchform.value.sortBy == 'date'"
								/>
								<label class="custom-control-label" for="soSortByDate">Newest to oldest</label>
							</li>
							<li class="custom-control custom-radio">
								<input type="radio" class="custom-control-input" name="sortBy" id="soSortByName"
									(change)="onSortChange($event)"
									value="name" [checked]="searchform.value.sortBy == 'name'"
								/>
								<label class="custom-control-label" for="soSortByName">A to Z</label>
							</li>
						</ul>
					</fieldset>
				</div>
			</div>
		</form>
	</ng-container>
</div>
