import { isPlatformBrowser } from '@angular/common';
import {
	Component, OnInit, OnChanges, SimpleChanges,
	Input, ApplicationRef, Inject, PLATFORM_ID, Output,
	EventEmitter, HostListener
} from '@angular/core';
import { Lesson } from 'src/app/model/lesson.model';
import { EventService } from 'src/app/services/event/event.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-lesson-container',
	templateUrl: './lesson-container.component.html',
	styleUrls: ['./lesson-container.component.scss']
})
export class LessonContainerComponent implements OnInit, OnChanges {
	@Input() sliderTitle: string;
	// @Input() lessons: Lesson[];
	@Input() type: string;
	@Input() lessons: any[];
	@Input() slider: boolean = true;
	@Input() hideControls: boolean = false;
	@Input() useMini: boolean = false;
	@Input() maxSize: number = 4;
	@Input() minSize: number = 1;
	@Input() smaller: boolean = false;
	@Input() isMobile: boolean = false; // this.DeviceDetectorService.isMobile()
	@Input() clipVertically: boolean = false;
	@Input() clipHorizontally: boolean = false;
	@Input() sort: boolean = false;
	@Input() auth: boolean = true;
	@Input() addSoonCard: boolean = false;
	@Output() openSeeMore: EventEmitter<any> = new EventEmitter();
	// @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
	// 	if (this.selectedLesson) {
	// 		this.closeFlipCard();
	// 	}
	// }
	public useSlider: boolean = false;
	public sliderIndex: number = 0;
	public sliderTransition: boolean = true;
	public sliderTransitionTime: number = 0.5;
	// public selectedLesson: Lesson;
	public selectedLesson: any;
	public flipCardStyle: any;
	public sortBy: string = '';
	// private flipping: boolean = false;
	public timerToClose;
	public curSize: number = 0;
	public mobileCards: any[] = [];
	public moreCards: boolean = false;
	private SoonCard: Object = { id: -1, title: 'Coming Soon', blurb: 'New content is on the horizon.' };
	public curTypeDesc: string = null;
	private typeDesc: Object = {
		'lesson': 'Highly interactive, inquiry-driven digital learning explorations',
		'collection': 'Expertly curated content bundles designed to support instructional units',
		'visualization': 'Simulations and digital interactives for exploring scientific concepts',
		'content': 'Content created and shared by our vibrant community of educators'
	};

	constructor(
		private appRef: ApplicationRef,
		private event: EventService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
	) { }

	// private getScreenSize() {
	//  if (this.isMobile) { return true; }
	// 	const width = this.window.nativeWindow.innerWidth;
	// 	if(width < 576) return 'xs';
	// 	else if (576 <= width && width < 768) return 'sm';
	// 	else if (768 <= width && width < 992) return 'md';
	// 	else if (992 <= width && width < 1200) return 'lg';
	// 	else if (992 <= width && width < 1200) return 'xl';
	// 	else return 'xxl';
	// }

	ngOnInit(): void {
		this.useSlider = !!this.slider;
		this.convertSpacing(this.getCardSpacing());
		this.curTypeDesc = this.typeDesc[this.type] || null;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(this.lessons?.length <= this.sliderIndex) {
			this.sliderIndex = 0;
		}
		if (this.isMobile && changes['lessons']) {
			// console.log('change', lessons);
			this.mobileCards = [];
			this.mobileLessons();
		}
	}

	public async moveSlider(dir: boolean) {
		this.sliderTransition = false;
		// const first = this.sliderIndex === 0;
		// const last = this.sliderIndex >= this.lessons.length - 1;
		// if ((first && !dir) || (last && dir)) {
		// 	this.sliderIndex = last ? -1 : this.lessons.length;
		// }
		const skipSize = this.curSize > 1 ? this.curSize : 1;
		const posLast = this.lessons.length - 1;
		const posTarget = this.sliderIndex + ((dir ? 1 : -1) * (skipSize));
		let newIdx;
		if (0 <= posTarget && posTarget <= posLast) { newIdx = posTarget; }
		else if (posLast < posTarget) { newIdx = 0; }
		else if (posTarget < 0) { newIdx = this.lessons.length - skipSize + (this.addSoonCard ? 1 : 0); }
		else { console.error('Uncaught slider positioning'); }
		await new Promise<void>((resolve) => setTimeout(() => resolve(), 0));
		// console.log('click', this.sliderIndex, posTarget, posLast, newIdx)

		this.sliderIndex = newIdx;
		this.sliderTransition = true;
	}

	private adjustLessonModel() {
		if (this.useMini) return this.lessons;
		let ret = [...this.lessons];
		if (this.type === 'collection') { // adjust Collections to match template
			ret.map((card) => {
				// console.log('before: ', card);
				card['title'] = card?.Title || card?.title;
				card['description'] = card?.Description || card?.description;
				card['thumbnail'] = card?.cardImage || card?.thumbnail || {url: '/assets/images/image.jpg'};
				Object.assign({}, card, {boo: 'ha'});
			});
		}
		return ret;

	}
	public getLessons(): any[] {
		if (!this.lessons?.length) return [];
		let ret = this.adjustLessonModel();
		if (this.addSoonCard) ret.push(this.SoonCard);
		return ret;
	}

	public mobileLessons(): any[] {
		if (!this.lessons?.length) return [];
		let adjusted = this.adjustLessonModel();
		if (!this.mobileCards?.length) this.mobileCards = [...adjusted.slice(0,3)]; // load starters
		let arr = [...this.mobileCards]; 
		this.moreCards = arr?.length < adjusted?.length;
		// console.log(arr?.length, adjusted?.length);
		if (this.addSoonCard && !this.moreCards) arr.push(this.SoonCard);
		// if (this.type == 'collection') console.log('boot', arr);
		return arr;
	}
	public loadMoreCards(): void {
		if (!this.lessons?.length) return;
		// const pad = this.addSoonCard ? -1 : 0;
		const start = this.mobileCards?.length ? this.mobileCards.length : 0;
		let adjusted = this.adjustLessonModel();
		const arr = this.mobileCards?.length ? [...this.mobileCards.slice(0, start)] : []; // get current
		let nextCards = adjusted.slice(start, start + 6); // append more
		// console.log('load', arr, nextCards);
		this.mobileCards = [...arr, ...nextCards];
		// console.log('mobile', this.mobileCards);
	}

	// private sortedLessons(arr: any[]): any[] {
	// 	arr = arr?.length ? arr : [];
	// 	if (this.sortBy === 'name') {
	// 		return arr.sort((a, b) => (a.title > b.title ? 1 : -1));
	// 	} else if (this.sortBy === 'date') {
	// 		return arr.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
	// 	}
	// 	return arr;
	// }

	public getCardSpacing() {
		if (isPlatformBrowser(this.platformId)) {
			const width = this.window.nativeWindow.innerWidth;
			let ret = 0;
			if (width >= 1400) ret = 25;
			else if (width >= 992) ret = 33.33;
			else if (width >= 768) ret = 50;
			else ret = 100;
			return ret >= 100 / this.maxSize ? ret : 100 / this.maxSize;
		}
	}

	public convertSpacing(spacing: number) {
		let size = 0;
		switch (spacing) {
		case 16: size = 6; break;
		case 25: size = 4; break;
		case 33.33: size = 3; break;
		case 50: size = 2; break;
		case 100:
		default: size = 1; break;
		}
		// console.log(size);
		return this.curSize = size;
	}

	// public updateFlipping(e: boolean) {
	// 	this.flipping = e;
	// }

	public updateLesson(lesson, newLesson) {
		lesson = newLesson;
	}

	loginModalToggle() {
		this.openSeeMore.emit(true);
	}

	// private clickDelay: number = 0;
	// private static CLICK_INTERVAL: number = 100;
	// @HostListener('window:click', ['$event']) handleClick(event) {
	// 	if (Date.now() - this.clickDelay < LessonContainerComponent.CLICK_INTERVAL) {
	// 		return;
	// 	}
	// 	if (isPlatformBrowser(this.platformId) && this.selectedLesson) {
	// 		this.closeCard(event);
	// 	}
	// }
}
