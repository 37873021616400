<div class="range-div" [formGroup]="searchform">
	<h3><i class="fas fa-{{faIcon}}" aria-hidden="true" *ngIf="faIcon"></i> Grade Range</h3>
	<div class="custom-cb-range-group">
		<div class="custom-control custom-cb-range" *ngFor="let grade of gradeRanges; let i = index">
			<input
				type="checkbox"
				class="custom-control-input"
				id="range{{ i }}"
				value="{{ grade.name }}"
				(change)="onGradeChange($event)"
				name="range"
				checked="checked"
			/>
			<label class="custom-control-label" for="range{{ i }}">{{
				grade.name
			}}</label>
		</div>
	</div>
</div>
