<main role="main" class="flex-shrink-0 middle-section search-page">
	<!-- Modal: Login -->
	<div class="modal fade modal-auth" id="modal-signin-search" #modalLogin
	  role="dialog" aria-modal="true" aria-labelledby="modalLongTitle"
	  (keydown)="modalKeydown($event, 'login')" [ngClass]="{'show': toggleModal}" [ngStyle]="{ display: toggleModal == true ? 'block' : 'none' }">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title" id="modalLongTitle">Interested in seeing more?</h2>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="loginModalToggle()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-form-main">
						<div class="login-info">
							<p>Login or create an account for full access to simulations, community created content, and collaboration tools.</p>
						</div>
						<app-login title="loginModal" forgotPasswordRightAlignment="true"></app-login>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="toggleModal ? 'modal-backdrop fade show' : ''"></div>
	<!-- Modal: YouTube -->
	<div class="modal fade modal-auth" id="modal-yt-vid" #modalVideo
	  role="dialog" aria-modal="true" aria-labelledby="ytVidTitle"
	  (keydown)="modalKeydown($event, 'video')" [ngClass]="{'show': toggleModalYTVid}" [ngStyle]="{ display: toggleModalYTVid == true ? 'block' : 'none' }">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title" id="ytVidTitle">{{ modalData?.title || 'Associated Video' }}</h2>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalToggleYouTube()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body" *ngIf="modalData">
					<iframe width="100%" frameborder="0" title="{{ modalData?.title || 'Associated Video' }}"
					  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
					  [src]="modalGetYouTubeUrl()" tabindex="0"></iframe>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="toggleModalYTVid ? 'modal-backdrop fade show' : ''"></div>
	<!-- Modal: isMobile Launch-->
	<div class="modal fade modal-auth" id="modal-mobile-launch" #modalLaunch
	  role="dialog" aria-modal="true" aria-labelledby="modalLaunchTitle" aria-describedby="modalLaunchBody"
	  (keydown)="modalKeydown($event, 'launch')" [ngClass]="{'show': toggleModalLaunch}" [ngStyle]="{ display: toggleModalLaunch == true ? 'block' : 'none' }">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title" id="modalLaunchTitle">Desktop is preferred</h2>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalToggleCardLaunch()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body" id="modalLaunchBody">
					<p class="center"><img src="/assets/images/mobile-launch-mod.png" width="280" height="101" alt="device chart" /></p>
					<p>Not all features in this experience will perform as expected on mobile devices. It is best experienced on a desktop or laptop.</p>
					<div class="btnGroup center">
						<button class="btn btn-primary alt" (click)="modalToggleCardLaunch({}, true)">Continue</button>
						<button class="btn btn-primary" (click)="modalToggleCardLaunch()">Stay here</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="toggleModalLaunch ? 'modal-backdrop fade show' : ''" [attr.aria-hidden]="toggleModalLaunch ? 'false' : 'true'"></div>

	<!-- Search Content -->
	<div class="middle-inner">
		<div class="search-column" *ngIf="!loading">
			<app-search-form
				(searchToggleEvent)="searchToggle($event)"
				(checkboxUpdate)="checkboxUpdate($event)"
				[closeForm]="closeFilterForm"
			></app-search-form>
			<section class="featured-section login-featured-section search-filter-pad" [ngClass]="searchStatus ? 'pl-zero' : ''">
				<!-- <div class="search-div-bg"></div> -->
				<div class="featured-inner">
					<!-- Header -->
					<div class="header-container">
						<div class="row p-0 col-12 col-md-10 offset-md-1">
							<div class="header pr-0">
								<h2>Content Library</h2>
								<div class="table-selector" *ngIf="!resultsNone">
									<button class="btn btn-dark one" (click)="changeResultLayout('cards')" [ngClass]="{'active': resultLayout == 'cards'}">
										<i class="ico ico-check-solid check" *ngIf="resultLayout == 'cards'"></i>
										<i class="ico ico-rect-vert"></i>
										<i class="ico ico-rect-vert"></i>
										<i class="ico ico-rect-vert"></i>
										<i class="fas fa-th"></i>
										<span class="tooltip">Card View</span>
									</button>
									<button class="btn btn-dark two" (click)="changeResultLayout('table')" [ngClass]="{'active': resultLayout == 'table'}">
										<i class="ico ico-check-solid check" *ngIf="resultLayout == 'table'"></i>
										<i class="ico ico-list-solid"></i>
										<span class="tooltip">List View</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="container-fluid p-0">
						<!-- No Results -->
						<div class="row col-12 m-0 mt-5" *ngIf="resultsNone">
							<div class="no-results">
								<h2>No Results Found</h2>
								<p>We couldn’t find anything based on your search criteria. If you're searching for something outside of our ready-to-use content, try the following links or use the main menu navigation.</p>
								<p>
									<a href="/page/tour-it">Tour It</a>
									<span>|</span>
									<a href="/page/simple-author">Simple Author</a>
									<span>|</span>
									<a href="/page/events">Events & Professional Development</a>
								</p>
							</div>
						</div>
						<!-- View: Cards -->
						<div class="layoutCards" *ngIf="resultLayout == 'cards' && !resultsNone">
							<button id="explore-btn" class="down" (click)="exploreBtn()" aria-label="Explore content sections">
								Explore<br/>
								<i class="fas fa-chevron-down"></i>
							</button>
							<div class="cardRow" *ngIf="lessonLibrary && filteredLessons.length > 0">
								<app-lesson-container
									sliderTitle="Lessons"
									type = "lesson"
									[lessons]="filteredLessons"
									[slider]="isMobile ? false : slider"
									[clipHorizontally]="true"
									[clipVertically]="true"
									[minSize]="isMobile ? 2 : null"
									[smaller]="isMobile ? true : false"
									[isMobile]="isMobile || false"
									[addSoonCard]="true"
								></app-lesson-container>
							</div>
							<div class="cardRow" *ngIf="collectionLibrary && filteredCollections.length > 0">
								<app-lesson-container
									sliderTitle="Collections"
									type = "collection"
									[lessons]="filteredCollections"
									[slider]="isMobile ? false : slider"
									[clipHorizontally]="true"
									[clipVertically]="true"
									[minSize]="isMobile ? 2 : null"
									[smaller]="isMobile ? true : false"
									[isMobile]="isMobile || false"
									[addSoonCard]="true"
								></app-lesson-container>
							</div>
							<div class="cardRow" *ngIf="visualizationLibrary && filteredVisualizations.length > 0">
								<app-lesson-container
									sliderTitle="Visualizations"
									type = "visualization"
									[lessons]="filteredVisualizations"
									[slider]="isMobile ? false : slider"
									[clipHorizontally]="true"
									[clipVertically]="true"
									[minSize]="isMobile ? 2 : null"
									[smaller]="isMobile ? true : false"
									[isMobile]="isMobile || false"
									[addSoonCard]="true"
									[auth]="authenticated"
									(openSeeMore)="loginModalToggle()"
								></app-lesson-container>
							</div>
							<div class="cardRow" *ngIf="contributedLibrary && filteredContributed.length > 0">
								<app-lesson-container
									sliderTitle="Contributed"
									type = "content"
									[lessons]="filteredContributed"
									[slider]="isMobile ? false : slider"
									[clipHorizontally]="true"
									[clipVertically]="true"
									[minSize]="isMobile ? 2 : null"
									[smaller]="isMobile ? true : false"
									[isMobile]="isMobile || false"
									[addSoonCard]="true"
									[auth]="authenticated"
									(openSeeMore)="loginModalToggle()"
								></app-lesson-container>
							</div>
						</div>
						<!-- View: Table -->
						<div class="layoutTables" *ngIf="resultLayout == 'table' && !resultsNone">
							<div class="row">
								<div class="col-12 col-md-10 offset-md-1 mt-4">
									<div class="header-login" [ngClass]="{ 'd-none': api?.authenticated || (!api?.authenticated && tableView < 2)}">
										<button class="btn btn-primary" (click)="loginModalToggle()">Sign in for full access</button>
									</div>
									<div class="header-view">
										<button class="btnTableHeader active" [ngClass]="{'active': tableView == 0}" (click)="changeTableView(0)">Lessons</button>
										<button class="btnTableHeader" [ngClass]="{'active': tableView == 1}" (click)="changeTableView(1)">Collections</button>
										<button class="btnTableHeader" [ngClass]="{'active': tableView == 2}" (click)="changeTableView(2)">Visualizations</button>
										<button class="btnTableHeader" [ngClass]="{'active': tableView == 3}" (click)="changeTableView(3)">Contributed<span class="d-none d-lg-inline"> Content</span></button>
									</div>
									<app-search-view *ngIf="tableView == 0"
										[isMobile]="isMobile"
										[view]="tableView"
										[sort]="sortBy"
										[data]="lessonLibrary ? filteredLessons : []"
									></app-search-view>
									<app-search-view *ngIf="tableView == 1"
										[isMobile]="isMobile"
										[view]="tableView"
										[sort]="sortBy"
										[data]="collectionLibrary ? filteredCollections : []"
									></app-search-view>
									<app-search-view *ngIf="tableView == 2"
										[isMobile]="isMobile"
										[view]="tableView"
										[sort]="sortBy"
										[data]="visualizationLibrary ? filteredVisualizations : []"
									></app-search-view>
									<app-search-view *ngIf="tableView == 3"
										[isMobile]="isMobile"
										[view]="tableView"
										[sort]="sortBy"
										[data]="contributedLibrary ? filteredContributed : []"
									></app-search-view>
								</div>
							</div>
						</div>
						<!-- View: Popular Content -->
						<div class="cardRow popular-content">
							<div class="row p-0 m-0 mt-4">
								<h3 class="col-md-10 offset-md-1 explore-btn-ref">Popular content you may be interested in</h3>
								<div class="popular-container">
									<!-- <div class="row p-0 m-0">
										<div *ngFor="let mini of promotionSlides | slice:0:(resultsNone ? 6 : 3); let i=index" class="col-12 col-md-4">
											<app-lesson-card-mini [mini]="mini"></app-lesson-card-mini>
										</div>
									</div> -->
									<app-lesson-container
										sliderTitle=""
										type = "lesson"
										[lessons]="promotionSlides"
										[hideControls]="true"
										[useMini]="true"
										[slider]="isMobile ? false : slider"
										[clipHorizontally]="true"
										[clipVertically]="true"
										[minSize]="isMobile ? 2 : null"
										[smaller]="isMobile ? true : false"
										[isMobile]="isMobile || false"
										[addSoonCard]="false"
										*ngIf="promotionSlides.length > 0"
									></app-lesson-container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</main>
