import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

interface CustomRow {
	title: string,
	tag: string,
	ngss: string[],
	grade: string,
	duration: string,
	type: string,
	id: number
}

@Component({
	selector: 'app-search-view',
	templateUrl: './search-view.component.html',
	styleUrls: ['./search-view.component.scss']
})

export class SearchViewComponent implements OnInit, OnChanges {
	@Input() view: number = -1;
	@Input() sort: string = '';
	@Input() data: any[] = [];
	@Input() isMobile: boolean = false;
	public rows: any[] = [];
	public sortCol: number = 0;
	public viewLabel = 'Unknown';
	public viewUrl = '/unkown/';
	private hasRunInit: boolean = false;
	private sortDirection: number[] = [];
	private ariaSortStates: string[] = new Array(5).fill('descending');
	private columns: Object = {
		0: 'title',
		1: 'tag',
		2: 'ngss',
		3: 'grade',
		4: 'duration',
		5: 'type',
		6: 'id'
	}

	constructor(
		public api: ApiService,
		private router: Router,
		private event: EventService,
		private auth: AuthenticationService,
		private window: WindowRef
	) {
		//
	}

	private updateAriaSortStates(idx: number): void {
		// Set ARIA sort state for active column
		const dir = this.sortDirection[idx] || 0;
		this.ariaSortStates[idx] = dir === 1 ? 'ascending' : 'descending';
	}

	public sortByColumn(idx: number, skipChange: boolean = false): void {
		// skipChange = skipChange || (this.sortCol !== idx && !!this.sortDirection[idx]);
		this.sortCol = idx;
		if(!skipChange) this.sortDirection[idx] = (this.sortDirection[idx] == 1) ? -1 : 1;
		this.updateAriaSortStates(idx);
		this.rows.sort((a, b) => {
			let aValue = a[this.columns[idx]] || '';
			let bValue = b[this.columns[idx]] || '';
			// need to parse the column data?
			switch(idx) {
			case 4:
				aValue = Math.min(...aValue.split("-").map(Number));
				bValue = Math.min(...bValue.split("-").map(Number));
				break;
			}
			// console.log('sorting', aValue, bValue);
			// now do the sorting
			if (aValue < bValue && aValue !='') { //if empty, move to end of list
				return -1 * this.sortDirection[idx];
			} else if (aValue > bValue) {
				return 1 * this.sortDirection[idx];
			} else {
				return 0;
			}
		});
	}

	public thKeydown(ev: KeyboardEvent, idx: number): void {
		if (ev.key === 'Enter') {
			this.sortByColumn(idx);
			ev.preventDefault();
		}
	}

	public thAriaSort(idx: number = -1): string {
		return this.ariaSortStates[idx] || 'none';
	}

	public thClass(idx: number = -1, str: string = ''): string {
		let ngClass = str 
			+ ((this.sortCol == idx) ? ' active' : '') 
			+ (this.ariaSortStates[idx] ? ' '+this.ariaSortStates[idx].substring(0,3) : ' des');
		return ngClass;
	}
	
	public trKeyPress(e, id): void {
		if(e && e.key == "Enter") {
			this.trClick(id);
		}

	}
	public trClick(id): void {
		if(!id) { console.error('Row id not found', id); return; }
		let url = this.viewUrl + id;
		this.window.nativeWindow.open(url, '_self');
	}
	
	public tdNgssTagStr(tags): string {
		let arr = [];
		tags.map(tag => {
			if (tag?.label) arr.push(tag.label);
		});
		return arr?.join(", ") || '';
	}
	public tdNgssTypeIcon(row, idx): string {
		return row?.ngss[idx] ? 'fa-check' : 'fa-ellipsis-h'; 
	}
	public tdNgssTypeStr(types): string {
		let arr = [];
		types.map((val, i) => {
			if (val) {
				switch (i) {
				case 1: arr.push('ESS'); break;
				case 2: arr.push('LS'); break;
				case 3: arr.push('PS'); break;
				default: break;
				}
			}
		});
		return arr?.join(", ") || '';
	}

	// sendGlobalEvent() {
	// 	this.event.globalEvent({ action: 'sent-global-event' });
	// }

	private updateRowsFromData() {
		this.data.map(row => {
			switch (this.view) {
			case 0:
			case 3:
				this.rows.push({
					title: row.title,
					tag: row.CardTopic?.title,
					ngss: row.NgssTags,
					grade: row.gradeLevel?.length ? row.gradeLevel?.map(item => item?.Level).join(", ") : row.grade,
					duration: row.duration,
					// type: row.type,
					id: row.id
				});
				break;
			case 1:
			case 2:
				let ngssTypes = [];
				row.NgssTypes?.forEach(item => ngssTypes[item.id] = true); // 1: ESS, 2: LS, 3: PS
				this.rows.push({
					title: row.title || row.Title,
					tag: row.CardTopic?.title,
					ngss: ngssTypes,
					grade: row.gradeLevel?.length ? row.gradeLevel?.map(item => item?.Level).join(", ") : row.grade,
					duration: row.duration,
					type: row.visType,
					id: row.id
				});
				break;
			default: console.error('Uncaught type of SearchView view');
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!this.hasRunInit) return; // caught running before ngOnInit()
		if (changes['data']) {
			this.rows = [];
			this.updateRowsFromData();
			// this.sortByColumn(this.sortCol, true);
		}
		if(changes['sort'] && this.sort == 'name' && this.ariaSortStates[0]?.includes('desc')) {
			this.sortByColumn(0);
		}
	}
	
	async ngOnInit() {
		this.isMobile = this.isMobile || this.window.nativeWindow.innerWidth < 768;
		// Set viewLevel and viewUrl via [view]
		switch (this.view) {
		case 0:
			this.viewLabel = 'Lesson';
			this.viewUrl = '/lesson/';
			break;
		case 1:
			this.viewLabel = 'Collection';
			this.viewUrl = '/collection/';
			break;
		case 2:
			this.viewLabel = 'Visualization';
			this.viewUrl = '/visualization/';
			break;
		case 3:
			this.viewLabel = 'Contributed Content';
			this.viewUrl = '/content/';
			break;
		}
		// Populate rows via [data]
		this.updateRowsFromData();
		// Inital table sorting?
		if(this.sort == 'name') {
			this.sortByColumn(0);
			this.ariaSortStates[0] = 'ascending';
		}
		this.hasRunInit = true;
	}

	ngOnDestroy() { }
}
