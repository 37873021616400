<section class="banner-inner-page">
	<div class="banner-inner-div" *ngIf="lesson" [ngStyle]="{ 'background-image': 'url(' + lesson.cardImage?.url + ')' }"></div>

	<div class="banner-inner-content">
		<div class="container">
			<div class="banner-details">
				<div class="playbutton" *ngIf="lesson && lesson.YouTubeId">
					<button id="lesson-ytvideo" class="clickable" (click)="toggleVideo()">
						<img src="assets/images/icon/play-btn.png" alt="play button" />
					</button>
				</div>
				<app-lesson-title class="w-100" [title]="lesson.title || lesson.Title"
					[createdAt]="lesson.releaseDate || lesson.createdAt">
				</app-lesson-title>
				<div class="row row-custom">
					<div class="col-6">
						<app-community-contribution *ngIf="lesson.author" [authorImg]="lesson.authorImage?.url" [author]="lesson.author" [createdBy]="lesson.createdBy"></app-community-contribution>
					</div>
					<div class="col-6 counts-col">
						<app-lesson-counts [bookmarks]="bookmarks" [likes]="likes" (toggleLike)="toggleLike()"></app-lesson-counts>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="modal fade modal-auth" id="modal-yt-vid" #modalVideo
  role="dialog" aria-modal="true" aria-labelledby="ytVidTitle"
  (keydown)="modalKeydown($event)" [ngClass]="{'show': showVideo}" [ngStyle]="{ display: showVideo ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleVideo()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" *ngIf="lesson?.YouTubeId">
				<iframe width="100%" frameborder="0" title="{{ lesson?.title || 'Lesson Video' }}"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
					[src]="sanitizeHtml('https://www.youtube.com/embed/' + lesson?.YouTubeId)" tabindex="0"></iframe>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="showVideo ? 'modal-backdrop fade show' : ''"></div>