import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/API/api.service';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';

@Component({
	selector: 'app-lesson-banner',
	templateUrl: './lesson-banner.component.html',
	styleUrls: ['./lesson-banner.component.scss']
})
export class LessonBannerComponent implements OnInit {
	public apiUrl = environment.api_url;
	@Input() lesson: any;
	@Input() likes: number = 0;
	@Input() bookmarks: number = 0;
	@Input() bannerType: string;
	private loading: boolean = false;
	public liked: boolean = false;
	private minimetricId: string;
	public showVideo: boolean = false;

	@ViewChild('modalVideo',   { static: true }) modalVideo!: ElementRef;

	constructor(private sanitizer: DomSanitizer, private api: ApiService, private miniMetricService: MiniMetricsService) {}

	ngOnInit(): void {
		this.loading = false;
		
		if (this.api.authenticated) {
			this.api
				.get(
					`minimetrics?${this.miniMetricService.getQuery(this.bannerType)}=${this.lesson.id}&user.id=${this.api.user.id}&type=like`
				)
				.subscribe((res) => {
					res = res || [];
					this.liked = res.length > 0;
					if (this.liked) this.minimetricId = res[0].id;
				});
		}
	}

	public toggleVideo() {
		this.showVideo = !this.showVideo;
		console.log(this.showVideo);
		if (this.showVideo) {
			setTimeout(() => this.modalVideo.nativeElement.querySelector('button.close').focus(), 0);
		} else { 
			document.getElementById('lesson-ytvideo').focus();
		}
	}
	modalKeydown(event: KeyboardEvent): void {
		if (event.key === 'Escape') {
			this.showVideo = false;
		}
	
		if (event.key === 'Tab') {
			const focusableElements = this.modalVideo.nativeElement.querySelectorAll(
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
			);
			const firstElement = focusableElements[0];
			const lastElement = focusableElements[focusableElements.length - 1];
			if (event.shiftKey && document.activeElement === firstElement) {
				event.preventDefault();
				lastElement.focus();
			} else if (!event.shiftKey && document.activeElement === lastElement) {
				event.preventDefault();
				firstElement.focus();
			}
		}
	}

	public sanitizeHtml(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url.replace(/<[^>]*>/g, ""));
	}

	public toggleLike() {
		this.miniMetricService.toggleLike(this.bannerType, this.lesson.id).subscribe((res) => {
			this.liked = res > 0;
			this.likes += res;
		})
	}
}
