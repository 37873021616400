import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonContainerComponent } from './lesson-container/lesson-container.component';
import { LessonCardModule } from '../lesson-card/lesson-card.module';
import { LessonCardMiniModule } from '../lesson-card-mini/lesson-card-mini.module';
// import { LessonFlipModuleModule } from '../lesson-flip-module/lesson-flip-module.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [LessonContainerComponent],
	imports: [
		CommonModule,
		LessonCardModule,
		LessonCardMiniModule,
		// LessonFlipModuleModule,
		FormsModule
	],
	exports: [LessonContainerComponent]
})
export class LessonContainerModule {}
