<div>
    <div class="table-view" *ngIf="view == 0 || view == 3">
        <table>
            <thead>
                <tr>
                    <th class="clickable " tabindex="0"
                      [attr.aria-sort]="thAriaSort(0)"
                      (keydown)="thKeydown($event, 0)"
                      (click)="sortByColumn(0)"
                      [ngClass]="thClass(0)">
                        {{viewLabel}} Title
                        <span class="sorting"></span>
                    </th>
                    <th class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(1)"
                      (keydown)="thKeydown($event, 1)"
                      (click)="sortByColumn(1)"
                      [ngClass]="thClass(1)">
                        Main Tag
                        <span class="sorting"></span>
                    </th>
                    <th aria-sort="none">NGSS</th>
                    <th class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(3)"
                      (keydown)="thKeydown($event, 3)"
                      (click)="sortByColumn(3)"
                      [ngClass]="thClass(3)">
                        Grade Range
                        <span class="sorting"></span>
                    </th>
                    <th class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(4)"
                      (keydown)="thKeydown($event, 4)"
                      (click)="sortByColumn(4)"
                      [ngClass]="thClass(4)">
                        Duration
                        <span class="sorting"></span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="clickable" tabindex="0"
                    *ngFor="let row of rows"
                    (keydown)="trKeyPress($event, row?.id)"
                    (click)="trClick(row?.id)"
                >
                    <td class=""><div>{{ row?.title || '&nbsp;' }}</div></td>
                    <td class=""><div>{{ row?.tag || '&nbsp;' }}</div></td>
                    <td class="center">
                        <div *ngIf="!isMobile; else ngssTags">
                            <div *ngFor="let tag of row?.ngss">{{ tag?.label }}&nbsp;</div>
                        </div>
                        <ng-template #ngssTags><div>{{ tdNgssTagStr(row?.ngss) || '&nbsp;' }}</div></ng-template>
                    </td>
                    <td class="center"><div>{{ row?.grade || '&nbsp;' }}</div></td>
                    <td class=""><div>{{ row?.duration || '&nbsp;' }}</div></td>
                </tr>
                <tr *ngIf="rows.length == 0">
                    <td colspan="5">
                        No results found.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-view" *ngIf="view == 1 || view == 2">
        <table>
            <thead>
                <tr>
                    <th class="clickable {{ariaSortStates[idx]}}" tabindex="0"
                      [attr.aria-sort]="thAriaSort(0)"
                      (keydown)="thKeydown($event, 0)"
                      (click)="sortByColumn(0)"
                      [ngClass]="thClass(0)">
                        {{viewLabel}} Title
                        <span class="sorting"></span>
                    </th>
                    <th class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(1)"
                      (keydown)="thKeydown($event, 1)"
                      (click)="sortByColumn(1)"
                      [ngClass]="thClass(1)">
                        Main Tag
                        <span class="sorting"></span>
                    </th>
                    <th aria-sort="none" class="t-ngss row m-0">
                        <div class="col-12 p-0">NGSS</div>
                        <div class="col-4 p-0">LS</div>
                        <div class="col-4 p-0">ESS</div>
                        <div class="col-4 p-0">PS</div>
                    </th>
                    <th class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(3)"
                      (keydown)="thKeydown($event, 3)"
                      (click)="sortByColumn(3)"
                      [ngClass]="thClass(3)">
                        Grade Range
                        <span class="sorting"></span>
                    </th>
                    <th *ngIf="view == 1" class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(4)"
                      (keydown)="thKeydown($event, 4)"
                      (click)="sortByColumn(4)"
                      [ngClass]="thClass(4)">
                        Duration
                        <span class="sorting"></span>
                    </th>
                    <th *ngIf="view == 2" class="clickable" tabindex="0"
                      [attr.aria-sort]="thAriaSort(5)"
                      (keydown)="thKeydown($event, 5)"
                      (click)="sortByColumn(5)"
                      [ngClass]="thClass(5)">
                        Type
                        <span class="sorting"></span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="clickable" tabindex="0"
                    *ngFor="let row of rows"
                    (keydown)="trKeyPress($event, row?.id)"
                    (click)="trClick($row?.id)"
                >
                    <td class="">{{ row?.title }}</td>
                    <td class="">{{ row?.tag || '&nbsp;' }}</td>
                    <td class="t-ngss">
                        <div class="row m-0" *ngIf="!isMobile; else ngssTypes">
                            <span class="col-4 p-0"><i class="fas" [ngClass]="tdNgssTypeIcon(row, 2)"></i></span>
                            <span class="col-4 p-0"><i class="fas" [ngClass]="tdNgssTypeIcon(row, 1)"></i></span>
                            <span class="col-4 p-0"><i class="fas" [ngClass]="tdNgssTypeIcon(row, 3)"></i></span>
                        </div>
                        <ng-template #ngssTypes>{{ tdNgssTypeStr(row?.ngss) || '&nbsp;' }}</ng-template>
                    </td>
                    <td class="center">{{ row?.grade || '&nbsp;' }}</td>
                    <td class="">{{ (view == 1 ? row?.duration : row?.type) || '&nbsp;' }}</td>
                </tr>
                <tr *ngIf="rows.length == 0">
                    <td colspan="5">
                        No results found.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>